// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$nav-bg: $light; // Navigation Background
$nav-color: $dark; // Navigation Link Color

@mixin nav-button {
	// Default Link Styles
	padding: 1em rem($base-gap);
	text-decoration: none; 
	color: $nav-color;
}
%button-hover {
	// Link Hover
	color: $blue;
}
%button-active {
	// Link Active
	color: $blue;
	font-weight: 500;
}
%prev-icon {
	// Icon to previus Submenu
	font-family: $icon-font;
	content: map-get($icon-map, arrow);
	transform: rotateY(180deg);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%next-icon {
	// Icon to next Submenu
	font-family: $icon-font;
	content: map-get($icon-map, arrow);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$close-icon: map-get($icon-map, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$bar-height: 50px; // height (in px)
$bar-pos: top; // position (top, bottom)
$bar-hide: true; // if bar hides on scroll (true, false)

$bar-bg: $blue; // background-color
$bar-color: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include page-navi {
	background-color: $nav-bg;
	z-index: 1001;
	min-width: 80%;
	text-align: left;
	nav {
		@include ul {
			&.navi {
				li {
					@include nav-buttons {
						@include nav-button;
					}
					font-weight: 300;
					text-transform: uppercase;
					letter-spacing: rem(1px);
					border-bottom: 1px solid rgba($dark,0.2);
					&:last-child {
						border:none;
					}
					@include has-sub {
						@include sub {
							background: $nav-bg;

							&:before {
								padding: 1em rem($base-gap);
								background-color: darken($nav-bg, 6%);
							}
						}
					}
					@include nav-back {
					}
				}
			}
		}
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktop-version-functionality;

	#navigation {
		position: relative;
		background:none;
		nav {
			ul {
				&.navi {
					li {
						font-size: rem(14px);
						border:none;
						@include breakpoint(giant) {
							font-size: rem(16px);
						}
						@include breakpoint(huge) {
							font-size: rem(18px);
						}
						&.has-sub {
							.sub {
								background:$blue;
								li {
									transition: 0.3s;
									border-bottom: 1px solid rgba($light,0.2);
									a {
										color:$light;
									}
									
									&:hover , &.active{
										background:$mediumblue;
										a {
											color:$light;
										}
									}
								}
							}
						}
						&.nav-back {
							color:$light;
							&:hover {
								background:$mediumblue;
								color:$light;
							}
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: $bar-bg;
	color: $bar-color;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($base-gap);
	z-index: 1000;

	@include breakpoint($break) {
		display: none;
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
.navi-add {
	text-align: center;
	@include breakpoint(medium) {
		display: flex;
		flex-wrap:wrap;
		justify-content: space-between;
	}
	li {
		border-bottom: 1px solid rgba($dark,0.2);
		@include breakpoint(medium) {
			display:inline-block;
			border:none;
			text-transform: uppercase;
		}
		&:last-child {
			border:none;
		}
		a {
			display: block;
			padding:1rem;
			color:$dark;
			font-weight: 300;
			text-decoration: none;
			@include breakpoint (medium) {
				padding:0.3rem;
				font-size: rem(16px);
			}
			@include breakpoint (giant) {
				font-size: rem(18px);
			}
			@include breakpoint (huge) {
				font-size: rem(20px);
			}
			&:hover, &.active {
				color:$blue;
			}
			&.active {
				font-weight: 500;
			}
		}
	}

	li {
		margin-right: 1rem;
	}
}