// text
$color-main: #fff;
$color-medium: #eee;
$color-link: #eee;
$color-dark: #464a4d;

// backgrounds
$bg-fields: #464a4d;
$bg-main: #333;
$bg-submit: #3bb54a;
$bg-checkbox: #888a8c;
$bg-check-slider: #fff;

// misc
$border-stroke: 2px;
$spacing: 10px;
$border-radius: 4px;
$font-size: 11px;

// overwrite default notification styles
#privacy-ctrl {
	background: $bg-main;
	border-radius: $border-radius;
	color: $color-main;

	.note-content {
		padding: 0;
	}

	&,
	* {
		font-family: Arial, sans-serif;
	}
	
	&.hide,
	.hide {
		opacity: 0;
		visibility: hidden;
	}
	&.open {
		opacity: 1;
		visibility: visible;
	}
	.open & {
		height: calc(100vh - 40px);
	}
}

// main styles
#privacy-controller {
	display: block;
	font-size: $font-size;
	line-height: 1.3em;
	font-weight: normal;
}

.privacy-header {
	display: block;
	padding: $spacing;
	
	.privacy-text {
		padding-right: $spacing * 2;
	}
}

.privacy-text {
	display: block;
	margin-bottom: $spacing;
}

.privacy-buttons {
	:first-child {
		margin-bottom: rem(5px);
	}

	@include breakpoint(tiny) {
		display: flex;

		:first-child {
			margin-bottom: 0;
		}
	}

	.hide {
		display: none;
	}
}

.privacy-btn {
	width: 100%;
	display: flex;
	color: $color-dark;
	background: $bg-checkbox;
	cursor: pointer;
	border-radius: $border-radius;
	padding-top: $spacing/1.5;
	padding-bottom: $spacing/1.5;
	padding-left: 12px;
	padding-right: 12px;
	
	flex-wrap: nowrap;
	white-space: nowrap;
	justify-content: center;
	
	&:first-of-type {
		margin-right: $spacing;
	}
}
#privacy-confirm {
	background: $bg-submit;
	color: $color-main;
}

.privacy-link {
	cursor: pointer;
	color: $color-link;
	text-decoration: underline;
}

.privacy-fields {
	display: block;
	background: $bg-fields;
	max-height: 40vh;
	overflow-y: scroll;
	border-left: $border-stroke solid $bg-main;
	border-right: $border-stroke solid $bg-main;
	height:0;
	transition:800ms height ease-in-out;

	.privacy-controller.open &
	{
		height: 100vh;
	}
	
	label {
		cursor: pointer;
		position: relative;
		display: block;
		width: 100%;
		
		span {
			font-size: 1.2em;
			content: "";
			letter-spacing: 0;
			width: 2.2em;
			height: 1.2em;
			line-height: 1.2em;
			border-radius: 14px;
			display: block;
			text-align: center;
			background: $bg-checkbox;
			color: transparent;
			margin-right: $spacing;
			position: relative;
			
			&:before {
				content: "";
				width: 9px;
				height: 9px;
				border-radius: 50%;
				background: $bg-check-slider;
				position: absolute;
				left: 4px;
				top: 3px;
				opacity: 1;
				transition: 150ms left ease-in-out;
				z-index: 10;
			}
			
			&:after {
				content: attr(data-off) " ";
				display: inline-block;
				position: absolute;
				left: calc(100% + 5px);
				color: $color-medium;
				font-size: rem($font-size);
				word-break: normal;
			}
		}
	}
	input:checked + span:before {
		left: rem(17px);
	}
	input:checked + span {
		background: $bg-submit;
		
		&:after {
			content: attr(data-on) " ";
		}
	}
	input {
		display: none;
	}
}

.privacy-row {
	display: flex;
	flex-wrap: wrap;
	padding: $spacing;
	&:not(:last-of-type) {
		border-bottom: $border-stroke solid $bg-main;
	}
}

.privacy-cell {
	width: 50%;
	hyphens: auto;

	&:not(:last-of-type) {
		margin-bottom: $spacing;
	}
}

.privacy-footer {
	display: flex;
	padding: $spacing;
	flex-flow: row wrap;
	
	@include breakpoint(tiny) {
		justify-content: flex-end;
		flex-flow: unset;
	}

	.privacy-link {
		width:50%;	
		
		
		
		@include breakpoint(tiny) {
			width:auto;
		}

		&:not(:last-child) {
			padding-right: $spacing;
			margin-bottom: rem(5px);
		}
	}
}

#privacy-reset
{
	opacity:0.5
}

// independent badge
.privacy-control-badge {
	body.privacy-control-is-open & {
		left: -100%;
	}
	
	transform: rotate(90deg) translateX(-100%);
	display: inline-block;
	position: fixed;
	left: 0;
	bottom: rem(45px);
	transform-origin: bottom left;
	background: $bg-main;
	color: $color-main;
	border-top-left-radius: $border-radius;
	border-top-right-radius: $border-radius;
	font-size: rem(11px);
	line-height: rem(10px);
	text-decoration: none;
	padding: $spacing;
	transition: 300ms all ease-in-out;
	z-index:999998;



	&:hover, &:active, &:visited {
		color: $light;
		background:$blue;
	}

	@include breakpoint(large) {
		font-size:rem(12px);
		line-height: rem(12px);
	}
}