#page-wrap {
	body:not(.index) & {
		padding:3rem 0 0;
		@include breakpoint(large) {
			padding: 13rem 0 0;
		}
	}
}
// HEADER
//////////////////////////////
.topBar	{
	width: 100%;
	z-index: 99;
	padding:4rem 0 1.5rem;
	text-align: center;
	@extend %animated-transform;
	@include breakpoint(large) {
		position:fixed;
		top:0;
		padding:1rem 0;
		text-align: left;
	}
	&.scroll {
		@include breakpoint(large) {
			background:$light;
			box-shadow: 3px 3px 3px rgba($dark,0.2);
		}
	}
	.branding  {
		display: inline-block;
		img {
			width: auto;
		}
	}
	.row {
		align-items: center;
		justify-content: space-between;
	}
	body:not(.index) & {
		box-shadow: 3px 3px 3px rgba($dark,0.2);
		background:$light;
	}
		
}
#header {
	position: relative;
	width: 100%;
	padding:3rem 0 0;
	margin-bottom: 3rem;
	@include breakpoint(medium) {
		padding:3rem 0;
		margin-bottom: 6rem;
	}
	@include breakpoint(large) {
		padding:6rem 0 6rem;
	}
	@include breakpoint(giant) {
		padding:15rem 0 0;
	}
	@include breakpoint(full) {
		padding:18rem 0 0;
	}
	.branding {
		display: inline-block;
	}
	.row {
		justify-content: space-between;
		@include breakpoint(medium) {
			align-items:center;
		}
		@include breakpoint(giant) {
			align-items:flex-start;
		}
	}
	&:after {
		position: absolute;
		background:$lightblue;
		width: 60%;
		height:80%;
		position: absolute;
		content:'';
		left:0;
		top:0;
		z-index: -2;
		@include breakpoint(medium) {
			height:100%;
		}
		@include breakpoint(large) {
			height:92%;
		}
		@include breakpoint(giant) {
			height:93%;
			width:70%;
		}
		@include breakpoint(full) {
			height:90%;
		}
	}
	p {
		@extend .margin-bottom ;
		font-weight: 300;
		&.h1 {
			font-weight: 700;
			@include breakpoint(large) {
				font-size: 1.8rem;
			}
			@include breakpoint(giant) {
				font-size: 2.25rem;
			}
			@include breakpoint(full) {
				font-size: rem($h1-size);
				line-height:rem(75px)
			  }
			span {
				font-weight: 300;
				@include breakpoint(large) {
					padding-left: 2rem;
				}
			}
		}
	}
	.padLeft {
		@include breakpoint(large) {
			padding-left: 2rem;
		}
	}
	.picture {
		margin-top: 3rem;
		position: relative;
		width: 95%;
		display:block;
		@include breakpoint(medium) {
			margin-top: 0;
		}
		@include breakpoint(large) {
			margin-top:3rem;
		}
		@include breakpoint(giant) {
			margin-top:-7rem
		}
		@include breakpoint(full) {
			margin-top:-8rem
		}
		&:after {
			position: absolute;
			right:-1rem;
			top:-1rem;
			background:$blue;
			width:100%;
			height:100%;
			content:'';
			z-index:-1;
			@include breakpoint(full) {
				right:rem(-35px);
				top:rem(-35px);
			}
		}
	}
}

.contentContainer {
	.row {
		align-items:center;
		justify-content: space-between;
		flex-direction: column-reverse;
		@include breakpoint(medium) {
			flex-direction: unset;
		}
	}
	.padLeft {
		@include breakpoint(large) {
			padding-left: 2rem;
		}
	}
	picture {
		position: relative;
		width: 95%;
		display:block;
		margin-bottom: 3rem;
		float:right;
		&:after {
			position: absolute;
			left:-1rem;
			bottom:-1rem;
			background:$blue;
			width:100%;
			height:100%;
			content:'';
			z-index:-1;
			@include breakpoint(full) {
				left:rem(-35px);
				bottom:rem(-35px);
			}
		}
		img {
			margin-bottom: 0;
		}
	}
}

.zelteContainer {
	position: relative;
	padding:9rem 0 9rem;
	margin-top: -6rem;
	@include breakpoint(large) {
		padding: 12rem 0 12rem;
	}
	@include breakpoint(giant) {
		padding:14rem 0 16rem;
	}
	&:after {
		position: absolute;
		background:$lightblue;
		width: 60%;
		height:100%;
		position: absolute;
		content:'';
		right:0;
		top:0;
		z-index: -2;
		@include breakpoint(large) {
			width: 91%;
		}
		@include breakpoint(huge) {
			width: 87%;
		}
		@include breakpoint(full) {
			width: 75%;
		}
	}
	.padLeft {
		@include breakpoint(large) {
			padding-left: 2rem;
		}
	}
	.checks {
		margin-bottom: 1.5rem;
		@include breakpoint(tiny) {
			display: flex;
			flex-wrap: wrap;
		}
		p {
			margin-bottom: 0;
			@include breakpoint(tiny) {
				margin-right: 1rem;
				margin-bottom: 1rem;
			}
		}
		.icon {
			font-size: rem(18px);
			color:$blue;
			margin-right: 0.3rem;
		}
	}
}
.galleryContainer {
	margin-top: -3rem;
	@include breakpoint(giant) {
		margin-top: -6rem;
	}
	img {
		margin-bottom: 0.9rem;
	}
}

.ausstattungContainer {
	@include responsive-image('layout/bgTeaser.jpg', (tiny, small, medium, large), true);
	background-size: cover;
	padding:3rem 0;
	position: relative;
	z-index: 0;
	background-position: center;
	margin:3rem 0;
	@include breakpoint(small) {
		padding:6rem 0;
	}
	@include breakpoint(medium) {
		padding:6rem 0;
		margin:6rem 0;
	}
	@include breakpoint(large) {
		padding:8rem 0;
		margin:8rem 0;
	}
	@include breakpoint(giant) {
		padding:10rem 0;
	}
	.h2 {
		color:$light;
		@include breakpoint(large) {
			margin-bottom: 3rem;
		}
		span {
			color:$light;
			font-weight: 400;
			line-height: rem(28px);
		}
	}
	.btn {
		&:after {
			border:1px solid rgba($light,0.5);
		}
	}
}

.videoContainer {
	position: relative;
	padding:3rem 0;
	margin-top: 3rem;
	@include breakpoint(large) {
		padding: 6rem 0 6rem;
		margin-top: -14rem;
	}
	&:after {
		position: absolute;
		background:$lightblue;
		width: 45%;
		height:100%;
		position: absolute;
		content:'';
		left:0;
		top:0;
		z-index: -2;
		@include breakpoint(large) {
			height:70%;
		}
	}
	.video {
		@include responsive-image('layout/bgVideo.jpg', (tiny, small,  large), true);
		background-position: center;
		background-size: cover;
		position: relative;
		padding:3rem 0;
		width: 97.7%;
		float: right;
		margin-top: 3rem;
		@include breakpoint(small) {
			padding:8rem 0;
		}
		@include breakpoint(large) {
			padding:10rem 0;
		}
		@include breakpoint(giant) {
			padding:14rem 0;
		}
		&:after {
			position: absolute;
			left:-1rem;
			bottom:-1rem;
			background:$blue;
			width:100%;
			height:100%;
			content:'';
			z-index:-1;
			@include breakpoint(full) {
				left:rem(-35px);
				bottom:rem(-35px);
			}
		}
	}
}

.contactContainer {
	text-align: center;
	margin:3rem 0;
	.h2 {
		@include breakpoint(large) {
			margin-bottom: 3rem;
		}
	}
	.btn {
		text-transform: none;
		letter-spacing: 0;
		padding:1rem 0.8rem 1rem 1.4rem;
		display: block;
		font-size: 16px;
		margin-bottom: 1.5rem;
		@include breakpoint(small) {
			padding:1rem 1.2rem 1rem 2.5rem;	
			font-size: rem(20px);
			display:inline-block;
			margin:1rem;
		}
		.icon {
			margin-right: 0.3rem;
			&.icon-mobile {
				font-size: rem(28px);
				vertical-align: top;
			}
			&.icon-fax,&.icon-plane {
				margin-top: -5px;
			}
		}
		a {
			color:$light;
			text-decoration: none;
			@extend %animated-transform;
			&:hover {
				color:$mediumblue;
			}
		}
		&.noBtn {
			cursor:auto;
			&:hover {
				color:$light;
				&:after {
					right: -7px;
					top:-7px;
				}
				&:before {
					left:-5px;
				}
			  }
		}
	}
}

.navContainer {
	padding:1.5rem 0;
	background:$lightblue;
	text-align: center;
	@include breakpoint(medium) {
		padding:3rem 0;
	}
	.branding {
		display: inline-block;
		margin-bottom: 1.5rem;
		img {
			width: auto;
		}
	}
	.row {
		align-items: center;
		justify-content: space-between;
	}
}
// MAIN CONTENT
//////////////////////////////

main {
	display: block;
	
	p,
	ol,
	ul,
	dl,
	table {
		@extend .margin-bottom ;
		font-weight: 300;
	}

	ul:not(.unstyled-list),
	dl:not(.unstyled-list),
	ol:not(.unstyled-list) {
		@extend .styled-list;
	}

	img {
		margin-bottom:rem($base-gap*2);
	}
}

#privacy-policy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {
	margin-top: 3rem;
	body:not(.index) & {
		@include breakpoint(large) {
			margin-top: 6rem;
		}
	}
	iframe {
		height: rem(250px);
		margin-bottom: -8px;
		@include breakpoint(medium) {
			height: rem(450px);
		}
		@include breakpoint(large) {
			height: rem(550px);
		}
	}
}

.zeltOutter {
	background:$lightblue;
	padding:1.5rem 1.5rem 0 1.5rem;
	margin-bottom: 1.5rem;
	.hr {
		background:rgba($dark,0.2);
	}
	@include breakpoint(giant) {
		padding:3rem 3rem 1.5rem 3rem;
	}
}

.contactBar {
    ul {
        display: none;

        @include breakpoint(large) {
            display: flex;
            justify-content: space-around;
        }


        }
         .phone {
            div {
                min-width: 10rem!important;
            }
        }
          .mail {
            div {
                min-width: 15rem!important;
            }
        }
    .link-holder {
        float: left;
        position: relative;
        width: 50px;
        height: 50px;

 
        

        @include breakpoint(giant) {
            border: none;
        }

        @include breakpoint(huge) {
            top: 0px;
        }


        .icon {
            display: inline-block;
            padding: 13px 6px;
			&.icon-mobile {
				font-size: rem(28px);
				margin-top: -4px;
			}
        }

        &:hover {
            &>div {
                visibility: visible;
                opacity: 1;
            }
        }

        .icon {

            &:before {
                color: $blue;
            }
        }
       

        div {
            background: $blue;
            position: absolute;
            color: $light;
            padding: 1rem;
            z-index: 888;
            text-align: center;
            top: 0;
            right: 0;
            margin-top: 2.6rem;
            visibility: hidden;
            opacity: 0;
            min-width: 19rem;
            font-weight: 400;
            transition: 300ms linear;

            a {
                color: $light;

                &:hover {
                    text-decoration: none;
                }
            }
        }

    }
}

body.cms-liveeditor-is-opened {
	.zelteContainer	, .galleryContainer,.ausstattungContainer,.videoContainer,.contactContainer ,#header{
		display: none;
	}
	#footer {
		iframe {
			display: none;
		}
	}
	.contentContainer {
		
			display: none;
		
	}
	#topBar {
		position: relative;
	}
}
.marginTopList {
	margin-top: -1rem;
	@include breakpoint (large) {
		margin-top: 3.5rem;
	}
}