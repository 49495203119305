* {
  margin: 0;
  padding: 0;

  &,
  &:before,
  &:after {
    box-sizing: inherit;
  }
}

::selection {
}
::-moz-selection {
}

// DO NOT set font-size and line-height here!
// Adjust $base-font-size and $base-line-height in _config.scss
html {
  background: $light;
  box-sizing: border-box;
  color: $dark;
  font-family: $main-font;
  font-size: 100% * ($base-font-size / 16px);
  hyphens: manual;
  line-height: rem($base-line-height);
  scroll-behavior: smooth;

  /* disable text resize in landscape. e.g. on iphone */
  text-size-adjust: none;
}

body {
  line-height: rem($base-line-height);
  opacity: 1 !important;
}

iframe,
[data-iframe] {
  border: none;
  width: 100%;
}

address {
  font-style:normal;
}

/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @extend .margin-bottom;
  display: block;
  text-rendering: optimizeLegibility;
  hyphens: auto;

  /* Use this to let headlines break in Chrome Desktop
	body.platformWindows.chrome &, body.platformLinux.chrome & {
		word-break: break-all;
	}
	*/
}

h1,
.h1 {
  font-family: $display-font;
  font-size: rem(30px);
  line-height: rem(40px);
  font-weight: 700;
  text-transform: uppercase;
  color:$blue;
  @include breakpoint(large) {
    font-size: 2.3rem;
    line-height:2.7rem;
  }
  @include breakpoint(giant) {
    font-size: rem($h1-size);
    line-height:rem(75px)
  }
  span {
    display:block;
    font-weight: 300;
    color:$dark;
    font-size: rem(20px);
    text-transform: none;
    @include breakpoint(large) {
      font-size: rem(25px);
      line-height: rem(40px);
    }
  }
}

h2,
.h2 {
  font-size: rem(28px);
  line-height: rem(38px);
  font-weight: 700;
  text-transform: uppercase;
  color:$blue;
  @include breakpoint(large) {
    font-size: rem($h2-size);
    line-height: rem(55px);
  }
  span {
    display:block;
    font-weight: 300;
    color:$dark;
    font-size: rem(20px);
    text-transform: none;
    @include breakpoint(large) {
      font-size: rem(25px);
      margin-top: 0.5rem;
      line-height: rem(35px);
    }
  }
}

h3,
.h3 {
  font-size: rem(26px);
  color:$blue;
  font-weight: 700;
  line-height: rem(36px);
  @include breakpoint(large) {
    font-size: rem(38px);
    line-height: rem(48px);
  }
}

h4,
.h4 {
  font-size: rem(24px);
  color:$blue;
  font-weight: 700;
  line-height: rem(34px);
  @include breakpoint(large) {
    font-size: rem($h4-size);
    line-height: rem(46px);
  }
}

h5,
.h5 {
  font-size: rem(22px);
  color:$blue;
  line-height: rem(32px);
  font-weight: 700;
  @include breakpoint(large) {
    font-size: rem($h5-size);
    line-height: rem(42px);
  }
}

h6,
.h6 {
  font-size: rem(20px);
  color:$blue;
  line-height: rem(30px);
  font-weight: 700;
  @include breakpoint(large) {
    font-size: rem($h6-size);
    line-height: rem(40px);
  }
}

/**
 * Links
 */
a {
  color: $mediumblue;
  word-wrap: break-word;
  text-decoration: none;
  @extend %animated-transform;

  &:hover {
    color: $blue;
  }
  &:focus {
    color: darken($secondary, 50%);
  }
  &:active {
    color: darken($secondary, 30%);
  }

  img {
    border: none;
  }

  &[href^="tel"] {
    color: inherit;
    text-decoration: underline;
  }
}

a:not(.btn):focus,
[tabindex]:focus {
  outline: $outline;
  outline-offset: rem(5px);
}

hr,
.hr {
  display: flex;
  background: $medium;
  border: none;
  clear: both;
  height: rem(1px);
  margin: 2rem 0 3rem;
  flex-basis: 100%;
}

ul,
ol {
  list-style: none;

  &.styled-list {
    li {
      padding-left: rem(30px);
      margin-bottom: rem(7px);

      &:before {
        @extend .icon;
        @extend .icon-long-arrow;
        text-indent: rem(-30px);
        color:$blue;
      }
    }

    ul {
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
}

dl {
  &.styled-list {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      background: rgba($medium, 0.8);
      padding: rem(15px);
    }

    dt {
      padding-bottom: 0;
      font-weight: bold;
    }

    dd {
      padding-top: 0;

      + dt {
        margin-top: rem(10px);
      }

      + dd {
        margin-top: rem(-15px);
      }
    }
  }

  &.float-list {
    @include pie-clearfix;

    dt,
    dd {
      display: block;
      vertical-align: top;
      float: left;
    }

    dt {
      padding-right: 0.5rem;
      width: 40%;
      clear: left;
    }

    dd {
      width: 60%;
    }
  }
}

ol {
  &.styled-list
  {
    counter-reset: item;
  
    li {
      counter-increment: item;
  
      &:before {
        content: counters(item, ".") ". ";
      }
    }
  
    ol {
      margin-bottom: 0;
  
      li {
        &:before {
          content: counters(item, ".") " ";
          margin-right: rem(5px);
        }
      }
    }
  }
}

/*
 * responsive images
 */

img {
  vertical-align: bottom;
  float: none;
  height: auto;
  max-width: 100%;
  width: 100%;

  &[src^='http://cms.'], &[src^='https://cms.'] {
    max-width: none;
    width: auto;
  }

  [data-rel] &,
  .lightbox-image & {
    margin-bottom: 0;
  }

  &[data-src] {
    @extend %lazy-loading;
  }
}

/**
 * Tables
 */
main {
  table {
    display: table;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    text-align: left;
    font-size: rem($base-font-size);

    @include breakpoint(small, max) {
      overflow-x: auto;
      display: block;
    }

    caption {
      text-align: left;
      padding: rem($base-gap) rem($base-gap) rem(25px);
      display: table-caption;
      font-weight: bold;
      font-size: 1.2rem;
    }

    thead {
      border-collapse: collapse;
      display: table-header-group;
    }

    tbody {
      width: 100%;
      overflow-x: scroll;
      display: table-row-group;

      tr {
        padding: rem($base-gap) 0;

        &:not(:last-child) {
          border-bottom: rem(1px) solid $medium;
        }
      }
    }

    tr {
      display: table-row;
    }

    th,
    td {
      padding: rem($base-gap);
      display: table-cell;
      vertical-align: top;
    }

    @include breakpoint(small, max) {
      td {
        min-width: 50vw;
      }
    }
  }
}
